<template>
  <div class="profile-authentication-wrapper padding15">
    <div class="level-box" v-for="(item, index) in AuthLevel" :key="index" @click="goVerify(item.level)">
      <div class="ub ub-btw">
        <div class="font14 main-text-1">
          {{ $t(`profile.verifications.${item.label}`) }}
        </div>
        <div class="font14">
          <div v-if="item.level === verifyStep || item.level > verifyStep" class="ub" :class="{
            'btn-text-color': verifyStep === item.level,
            'main-text-3':item.level > verifyStep
          }">
            {{ $t('profile.verifications.verify') }}
            <img v-if="verifyStep === item.level" src="@/assets/images/profile/rightArrowB.svg"/>
            <img v-else src="@/assets/images/profile/rightArrow.svg"/>
          </div>
          <div class="ub btn-text-color" v-else>
            <img src="@/assets/images/profile/verifiedStatusB.svg"/>
            {{ $t('profile.verifications.approved') }}
          </div>
        </div>
      </div>
      <div class="ub ub-btw" style="margin-top: 8px;">
        <div class="font12 main-text-3">
          {{ $t('profile.verifications.withdrawalLimit') }}
        </div>
        <div class="font12 main-text-3">
          {{ $t('profile.verifications.amountLimit24h') }} {{ item.withdrawalLimit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authentication',
  data () {
    return {
      verifyStep: 1,
      AuthLevel: [
        {
          label: 'level1',
          level: 1,
          withdrawalLimit: '10BTC'
        },
        {
          label: 'level2',
          level: 2,
          withdrawalLimit: '100BTC'
        },
        {
          label: 'level3',
          level: 3,
          withdrawalLimit: '100BTC'
        }
      ]
    }
  },
  created () {
  },
  methods: {
    goVerify (level) {
      if (this.verifyStep === level) {
        const targetPath = 'verifyStep' + level
        this.$router.push(`/profile/${targetPath}`)
      }
    }
  }
}
</script>
<style lang="less">
.profile-authentication-wrapper {
  box-sizing: border-box;
  .level-box{
    margin-top: 32px;
    padding: 24px 32px;
    border-radius: 16px;
    border: 1px solid #ECEAEF;
  }
}
</style>
